import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import LinkCard from "../../components/LinkCard"

const IndexPage = () => {
    return (
        <Layout pageTitle="Conference &amp; Awards - Brand Assets">
            <Row>
                <Col>
                    <Link to="/">Back</Link>
                </Col>
            </Row>
            <Row className="mt-5 justify-content-center">
                <LinkCard to="/conference/expert-pods/expert-pods-index" title="Expert Pods" bg="pink"/>
                <LinkCard to="/conference/workshops/workshops-index" title="Workshops" bg="pink" />
                <LinkCard to="/conference/logos" title="Logos" />
                <LinkCard to="/conference/standard-graphics/standard-graphics" title="Standard Graphics"/>
                <LinkCard to="/conference/standard-graphics/templates" title="Templates"/>
                <LinkCard to="/conference/press-releases" title="Press Releases & Emails"/>
            </Row>
        </Layout>
    )
}

export default IndexPage
